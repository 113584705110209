import React, { Component, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShippingFast } from "@fortawesome/free-solid-svg-icons";

import "./cart-tracker.scss";

const hideOnPaths = [
	"/",
	"/collections/limited-edition",
	"/collections/limited-edition/",
];

export function CartTracker({ cart, location, geoLocation }) {
	if (hideOnPaths.includes(location.pathname)) {
		return null;
	}

	let SHIPPING_THRESHOLD = 50;

	if (geoLocation === "AU" || geoLocation === "GB" || geoLocation === "CA") {
		SHIPPING_THRESHOLD = 75;
	}

	const subTotal = cart?.subtotalPrice?.amount || 0;
	const deficit = SHIPPING_THRESHOLD - subTotal;
	let percentage = (subTotal / SHIPPING_THRESHOLD) * 100;

	if (percentage > 100) {
		percentage = 100;
	}

	let message = `You are $${deficit.toFixed(2)} from Free Shipping`;

	if (deficit <= 0) {
		message = "You qualify for Free Shipping!";
	}

	return (
		<div className="cart-tracker">
			<span>
				{message}
				<FontAwesomeIcon
					icon={faShippingFast}
					style={{
						marginLeft: "0.8rem",
						position: "relative",
						top: "2px",
					}}
				/>
			</span>
			<div className="cart-tracker__container">
				<div
					className="cart-tracker__bar"
					style={{ width: `${percentage}%` }}
				/>
			</div>
		</div>
	);
}
