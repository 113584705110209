import axios from "axios";

import { EMAIL_SIGNUP_URL } from "../config";
import { submitSentryError } from "../providers/error-boundary";

const signupForMailingList = async (list, email, tags) => {
	let payload = {
		list,
		email,
	};

	if (tags) {
		payload.tags = tags;
	}

	const headers = {
		"Content-Type": "application/json",
	};

	return axios
		.post(EMAIL_SIGNUP_URL, payload, { headers })
		.catch((error) =>
			submitSentryError(error, {
				tag: { api: "Shopify", action: "emailSignUp" },
			})
		);
};

export default signupForMailingList;
