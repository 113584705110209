import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './mail-signup.scss';
import signupForMailingList from '../../actions/email-signup';

const trackLead = (data = null) => {

    // eslint-disable-next-line
    if (process.env.NODE_ENV === `production` && fbq && typeof fbq === `function`) {
        // eslint-disable-next-line
        fbq('track', 'Lead', data);
    }

}

class MailSignup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            isSubmitting: false,
            submitted: false,
            ctaText: this.props.ctaText ? this.props.ctaText : 'SUBMIT',
            errorMessage: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({ email: event.target.value, errorMessage: '' });
    }

    handleSubmit() {
        if (this.state.submitted || this.state.isSubmitting) { return };

        if (!this.state.email || this.state.email === "") {
            this.setState({ errorMessage: "Please provide a valid email address." })
            return;
        }

        this.setState({ isSubmitting: true })
        signupForMailingList(this.props.list, this.state.email, this.props.tags)
            .then((result) => {
                this.setState({ isSubmitting: false, submitted: true });
                if (!result.data) { return }
                if (result.data.status !== 'subscribed' && result.data.detail) {
                    this.setState({ submitted: false, errorMessage: result.data.detail });
                    return;
                }

                trackLead({ content_name: this.props.tags[0] });
            });
    }

    render() {

        const ctaTitle = this.state.submitted ? "THANK YOU!" : this.state.ctaText;
        const labelStyle = this.props.labelColor
            ? { color: this.props.labelColor }
            : {}

        return (
            <div className="mail-signup">
                <label className='mail-signup__label' style={labelStyle}>Your Email:</label>
                <div className="mail-signup__notify">
                    <input
                        type="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        className="mail-signup__input mail-signup__notify-email"
                        placeholder="franklin@tearribles.com"
                    />
                    <button
                        className="mail-signup__button"
                        title={ctaTitle}
                        onClick={this.handleSubmit}
                    >
                        {this.state.isSubmitting
                            ? <div className="loader-3" style={{ transform: 'scale(0.8)' }}><span></span></div>
                            : ctaTitle
                        }
                    </button>
                </div>
                <div className="mail-signup__error-message" style={this.props.errorMessageColor ? { color: this.props.errorMessageColor } : null}>
                    {this.state.errorMessage}
                </div>
            </div>
        )
    }
}

MailSignup.propTypes = {
    list: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string),
    ctaText: PropTypes.string,
    errorMessageColor: PropTypes.string
}

export default MailSignup;