import React, { Component } from "react";
import PropTypes from "prop-types";
import { addHours, isAfter } from "date-fns";
import qs from "qs";

import checkSpecialOffer from "../utils/check-special-offer";
import getCart from "../actions/get-cart";
import pingBackend from "../actions/ping";

const CART_KEY = "_draco-cart";
const CART_EXP = "_draco-cart-exp";
const QUERY_STRING_STORE = "_draco-qs";

const recursivePing = () => {
	pingBackend().then(() => {
		setTimeout(() => {
			recursivePing();
		}, 150000);
	});
};

export const AWAIT_SET_CART = "wait";

class Cart {
	constructor(cart) {
		this.cart = cart;
		this.subscriptions = [];
	}

	setCart(cart) {
		this.cart = cart;
		this.subscriptions.forEach((f) => f());
	}

	subscribe(f) {
		this.subscriptions.push(f);
	}
}

class CartProvider extends Component {
	constructor(props) {
		super(props);

		this.state = {
			cart: {},
			query: {},
		};

		this.cart = new Cart({});
		this.setCart = this.setCart.bind(this);
	}

	componentWillReceiveProps({ cart }) {
		if (cart) {
			this.cart.setCart(cart);
		}
	}

	componentDidMount() {
		const cartId = localStorage.getItem(CART_KEY);
		const cartExpireTime = localStorage.getItem(CART_EXP);

		const now = new Date();
		const cartExpired = isAfter(now, cartExpireTime);

		if (cartExpired) {
			this.resetLocalStorage();
		}

		if (cartId && !cartExpired) {
			getCart(cartId, this.setCart);
		}

		recursivePing();

		let query = {};

		const storedQueryString = localStorage.getItem(QUERY_STRING_STORE);
		if (storedQueryString) {
			const stored = JSON.parse(storedQueryString);
			query = { ...query, ...stored };
		}

		if (this.props.location?.search) {
			const search = this.props.location.search.replace("?", "");
			const newParams = qs.parse(search);
			query = { ...query, ...newParams };
		}

		this.setState({ query });
	}

	resetLocalStorage() {
		localStorage.removeItem(CART_KEY);
		localStorage.removeItem(CART_EXP);
	}

	setCart(cart) {
		if (cart.completedAt) {
			this.cart.setCart({});
			this.resetLocalStorage();
			return;
		}

		if (cart.lineItems.edges) {
			cart.lineItems = cart.lineItems.edges.map((item) => {
				let variant = item.node.variant.id
					? item.node.variant.id
					: item.node.variant;
				const lineItem = Object.assign({}, item.node, { variant });

				if (
					item &&
					item.node &&
					item.node.variant &&
					item.node.variant.weight
				) {
					lineItem.weight = item.node.variant.weight;
				}
				if (
					item &&
					item.node &&
					item.node.variant &&
					item.node.variant.weightUnit
				) {
					lineItem.weightUnit = item.node.variant.weightUnit;
				}
				if (
					item &&
					item.node &&
					item.node.variant &&
					item.node.variant.product &&
					item.node.variant.product.tags
				) {
					lineItem.tags = item.node.variant.product.tags;
				}
				if (
					item &&
					item.node &&
					item.node.variant &&
					item.node.variant.product &&
					item.node.variant.product.productType
				) {
					lineItem.productType =
						item.node.variant.product.productType;
				}
				if (
					item &&
					item.node &&
					item.node.variant &&
					item.node.variant.title
				) {
					lineItem.variantTitle = item.node.variant.title;
				}
				if (
					item &&
					item.node &&
					item.node.variant &&
					item.node.variant.sku
				) {
					lineItem.sku = item.node.variant.sku;
				}

				return lineItem;
			});
		}

		if (cart.id) {
			const now = new Date();
			localStorage.setItem(CART_KEY, cart.id);
			localStorage.setItem(CART_EXP, addHours(now, 6));
		}

		return checkSpecialOffer(cart, this.setCart, this.state.query).then(
			(result) => {
				if (result !== AWAIT_SET_CART) {
					this.cart.setCart(cart);
				}
			}
		);
	}

	getChildContext() {
		return {
			// ...this.state,
			cart: this.cart,
			setCart: this.setCart,
			resetCart: () => {
				this.cart.setCart({});
				this.resetLocalStorage();
			},
		};
	}

	render() {
		const { children } = this.props;

		return React.Children.only(children);
	}
}

CartProvider.childContextTypes = {
	cart: PropTypes.object.isRequired,
	setCart: PropTypes.func.isRequired,
	resetCart: PropTypes.func.isRequired,
};

export default CartProvider;
