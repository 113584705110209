import React from 'react';

const validLevels = [
    `fatal`,
    `error`,
    `warning`,
    `info`,
    `debug`
]

const requiredUserFields = [
    'id',
    'username',
    'email',
    'ip_address',
]

const checkForValidUserField = (acc, [key, value]) => {
    if (acc) {
        return acc;
    }

    if (!value) {
        return acc;
    }

    if (requiredUserFields.includes(key)) {
        return true;
    }

    return acc;
}

/**
 * Options format: 
 * ```
 * {
 *   user: {
 *     // Requires one of these
 *     id: value,
 *     username: value,
 *     email: string,
 *     ip_address: string,
 *   },
 *   tag: {
 *     key: value
 *   },
 *   extra: {
 *     key: value
 *   },
 *   level: `fatal` | `error` | `warning` | `info` | `debug`,
 *   fingerprint: "Do more research to use this"
 * }
 * ```
 * 
 * @param {Error} error Error object to send
 * @param {Object} options 
 */
export const submitSentryError = (error, options) => {
    const Sentry = window && window.Sentry;

    if (!Sentry) {
        return null;
    }

    Sentry.withScope(scope => {

        if (options && options.user) {
            const validUser = Object.entries(options.user).reduce(checkForValidUserField, false)
            if (validUser) {
                scope.setUser(options.user);
            }
        }

        if (options && options.tag) {
            Object.entries(options.tag).forEach(([key, value]) => {
                scope.setTag(key, value);
            })
        }

        if (options && options.extra) {
            Object.entries(options.extra).forEach(([key, value]) => {
                scope.setExtra(key, value);
            })
        }

        if (options && options.level) {
            if (validLevels.includes(options.level)) {
                scope.setLevel(options.level);
            }
        }

        Sentry.captureException(error);
    });
}

class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            errorInfo: null,
            eventId: null,
            cache: [],
        }
    }

    async componentDidMount() {
        const Sentry = await import('@sentry/browser');

        Sentry.init({
            dsn: "https://303de344e25f45818507c6feb5638cac@sentry.io/1457745",
            environment: process.env.NODE_ENV,
            enabled: (() => ["production", "stage"].indexOf(process.env.NODE_ENV) !== -1)()
        });

        if (Array.isArray(this.state.cache) && this.state.cache.length > 0) {
            this.state.cache.forEach((item) => {
                const { error, errorInfo } = item;

                Sentry.withScope(scope => {
                    scope.setExtras(errorInfo);
                    const eventId = Sentry.captureException(error);
                    this.setState({ eventId })
                });
            })
        }

        if (window) {
            window.Sentry = Sentry;
        }
    }

    componentDidCatch(error, errorInfo) {

        this.setState({ error, errorInfo });
        const Sentry = window && window.Sentry;

        if (!Sentry) {
            const newCache = this.state.cache.slice();
            newCache.push({ error, errorInfo });
            this.setState({ cache: newCache })
            return null;
        }

        Sentry.withScope(scope => {
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            this.setState({ eventId })
        });
    }

    render() {
        return this.props.children
    }

}

export default ErrorBoundary;