import axios from 'axios';

import { BACKEND_URL } from '../config';
import { submitSentryError } from '../providers/error-boundary';

const pingBackend = () => {

    const payload = { ping: true };

    let headers = {
        "Content-Type": 'application/json'
    }

    return axios.post(BACKEND_URL, payload, { headers })
        .catch((error) => {
            console.log(error.message);
            console.log(error.message === 'Network Error');
            submitSentryError(error, { tag: { api: 'Tearrbiles', action: 'pingBackend' } })
        });

}

export default pingBackend;