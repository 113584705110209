import axios from 'axios';

import parseCheckoutResponseFactory from '../utils/parse-checkout-response';
import checkoutFragment from './checkout.fragment';
import { submitSentryError } from '../providers/error-boundary';
import { BACKEND_URL } from '../config';

const REMOVE_LINE_ITEM_QUERY = `
  mutation checkoutLineItemsRemove($checkoutId: ID!, $lineItemIds: [ID!]!) {
    checkoutLineItemsRemove(checkoutId: $checkoutId, lineItemIds: $lineItemIds) {
      checkout {
        ...cart
      }
      userErrors {
        message
        field
      }
    }
  }

  ${checkoutFragment}
`

const deleteLineItem = ({ id }, checkoutId, setCart) => {

    if (id.includes('__')) {
        id = id.split('__').pop();
    }

    const variables = {
        lineItemIds: [id],
        checkoutId
    }

    const payload = {
        query: REMOVE_LINE_ITEM_QUERY,
        variables
    }

    let headers = {
        "Content-Type": 'application/json'
    }

    const parseCheckoutResponse = parseCheckoutResponseFactory('checkoutLineItemsRemove');

    return axios.post(BACKEND_URL, payload, { headers })
        .then(parseCheckoutResponse)
        .then((result) => setCart(result))
        .catch((error) => submitSentryError(error, { tag: { api: 'Shopify', action: 'deleteLineItem' } }));

}

export default deleteLineItem;