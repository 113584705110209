import axios from "axios";

import parseCheckoutResponseFactory from "../utils/parse-checkout-response";
import checkoutFragment from "./checkout.fragment";
import { submitSentryError } from "../providers/error-boundary";
import { BACKEND_URL } from "../config";

const ADD_ITEM_CHECKOUT_QUERY = `
  mutation checkoutLineItemsAdd($checkoutId: ID!, $lineItems: [CheckoutLineItemInput!]!) {
    checkoutLineItemsAdd(checkoutId: $checkoutId, lineItems: $lineItems) {
      checkout {
        ...cart
      }
      userErrors {
        message
        field
      }
    }
  }

  ${checkoutFragment}
`;

const addLineItem = (
	{ id, quantity, customAttributes = {} },
	checkoutId,
	setCart
) => {
	if (id.includes("__")) {
		id = id.split("__").pop();
	}

	const variables = {
		lineItems: [
			{
				variantId: id,
				quantity,
			},
		],
		checkoutId,
	};

	if (customAttributes) {
		Object.entries(customAttributes).forEach(([key, value]) => {
			variables.lineItems[0].customAttributes = {
				key,
				value,
			};
		});
	}

	const payload = {
		query: ADD_ITEM_CHECKOUT_QUERY,
		variables,
	};

	let headers = {
		"Content-Type": "application/json",
	};

	const parseCheckoutResponse = parseCheckoutResponseFactory(
		"checkoutLineItemsAdd"
	);

	return axios
		.post(BACKEND_URL, payload, { headers })
		.then(parseCheckoutResponse)
		.then((result) => {
			setCart(result);
			return result;
		})
		.catch((error) =>
			submitSentryError(error, {
				tag: { api: "Shopify", action: "addLineItem" },
			})
		);
};

export default addLineItem;
