const parseCheckoutResponseFactory = (field) => (result) => {

    if (result.status !== 200 || !result.data) {
        return Promise.reject("Error with request.");
    }

    if (result.data.errorMessage) {
        return Promise.reject(result.data.errorMessage);
    }

    if (result.data.errors) {
        const errors = Array.isArray(result.data.errors)
            ? result.data.errors
            : [result.data.errors]

        const message = errors.join('\n');
        return Promise.reject(message);
    }

    if (!result.data.data || !result.data.data[field]) {
        return Promise.reject("No data returned");
    }

    const { __typename, ...response } = result.data.data[field];

    if (response.userErrors && response.userErrors.length > 0) {
        let message = response.userErrors.map((error) => {
            return error.message;
        }).join('. ');
        return Promise.reject(message)
    }

    return response.checkout || response;
}

export default parseCheckoutResponseFactory;