module.exports = {
	BACKEND_URL:
		"https://c6io3dqyx1.execute-api.us-east-1.amazonaws.com/dev/proxy-v2",
	BACKEND_URL_V2: "http://localhost:2222",
	EMAIL_SIGNUP_URL:
		"https://iiedukuha8.execute-api.us-east-1.amazonaws.com/mail-list",
	GEO_URL: "https://c6io3dqyx1.execute-api.us-east-1.amazonaws.com/dev/geo",
	PRODUCTS_URL:
		"https://c6io3dqyx1.execute-api.us-east-1.amazonaws.com/dev/products",
	FACEBOOK_PIXEL: "198981680816218",
	AU_FACEBOOK_PIXEL: "1393620871189982",
	GOOGLE_ANALYTICS: "AW-11293311001",
	GOOGLE_TAG: "GTM-MGCHSHC",
	FERA_PUBLIC_KEY:
		"pk_0262f789e8337745ba30fe0b439a629aa7d97e45363af6128ec30a62d73a1abc",
};
