/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import Provider from './src/providers/inject-provider'
import Layout from './src/providers/inject-layout'

const preferDefault = m => (m && m.default) || m;

export const wrapPageElement = Layout;
export const wrapRootElement = Provider;

// export default {
//     wrapRootElement: Provider,
//     wrapPageElement: Layout
// }

  