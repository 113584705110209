const checkoutFragment = `
  fragment cart on Checkout {
    id
    completedAt
    webUrl
    createdAt
    updatedAt
    email
    note
    subtotalPrice {
	    amount
	    currencyCode
    }
    totalPrice {
	    amount
	    currencyCode
    }
    customAttributes {
      key
      value
    }
    lineItems(first: 100) {
      edges {
        node {
          customAttributes {
            key
            value
          }
          id
          quantity
          title
          variant {
            id
            title
            weight
            weightUnit
            sku
            product {
              productType
              tags
            }
          }
        }
      }
    }
    note
  }
`;

export default checkoutFragment;
