import React from "react";
import Link from "gatsby-link";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import whiteLogo from "../../assets/white-logo.svg";
import Container from "../../components/Container/container";
import MailSignup from "../../components/MailSignup/mail-signup";
import "./footer.scss";

const tinyImage = (
	<StaticQuery
		query={graphql`
			query tinyImage {
				file(relativePath: { eq: "yellow-head.png" }) {
					childImageSharp {
						fluid(maxWidth: 225) {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
			}
		`}
	>
		{(data) => (
			<Img
				fluid={data.file.childImageSharp.fluid}
				className="footer__tiny-image"
				alt="Tiny tear apart dog toy used for interactive play with owner of small dogs."
			/>
		)}
	</StaticQuery>
);

const Footer = (props) => {
	const domain =
		process.env.GATSBY_MYSHOPIFY_URL || "tearribles.myshopify.com";
	let style = {
		background: "#FFFFFF",
	};

	// TODO: Rework this to be more managable.
	// if (
	// 	!props.location?.pathname.includes("/product/tearrible") &&
	// 	!props.location?.pathname.includes("/fetch-yours/tearrible")
	// ) {
	// style.background = "#FFFFFF";
	// }

	const now = new Date();

	return (
		<div className="footer" style={style}>
			<Container style={{ position: "relative" }}>
				<div className="footer__wrapper">
					<div className="footer__left">
						<img
							src={whiteLogo}
							className="footer__logo"
							alt="Tearribles Logo"
						/>
					</div>
					<div className="footer__mail">
						<h4 className="footer__mail-heading">
							Be the first to hear about new toys
						</h4>
						<MailSignup
							tags={["Footer"]}
							list="703e63cb63"
							errorMessageColor="yellow"
							labelColor="#ffffff"
						/>
					</div>
					{/* <div>
                        <img src={whiteIcon} className="footer__logo" alt="Tearribles Logo"/>
                    </div> */}
					<div className="footer__right">
						<div className="footer__links">
							<h4 className="footer__link-heading">LINKS</h4>
							<Link className="footer__link" to="/products">
								Dog Toys
							</Link>
							{domain !== "tearriblesau.myshopify.com" ? (
								<Link
									className="footer__link"
									to="/products/combos"
								>
									Combos
								</Link>
							) : null}
							{/* <Link className="footer__link" to="/products/gear">Gear</Link> */}
							<Link className="footer__link" to="/faq">
								FAQ
							</Link>
							<Link className="footer__link" to="/story">
								Our Story
							</Link>
							<Link
								className="footer__link"
								to="/blog/prey-drive"
							>
								Blog
							</Link>
							{domain !== "tearriblesau.myshopify.com" ? (
								<>
									<a
										className="footer__link"
										href="https://tearribles.bixgrow.com/"
										target="_blank"
									>
										Affiliates
									</a>
									<a
										className="footer__link"
										href="https://vendor.tearribles.com/"
										target="_blank"
									>
										Vendors
									</a>
								</>
							) : null}
							<Link className="footer__link" to="/returns">
								Returns & Exchanges
							</Link>
							<Link className="footer__link" to="/shipping">
								Shipping
							</Link>
						</div>
						<div className="footer__links">
							<h4 className="footer__link-heading">
								International Sites
							</h4>
							{domain !== "tearriblesau.myshopify.com" ? (
								<a
									className="footer__link"
									href="https://au.tearribles.com/"
								>
									Australia
								</a>
							) : null}
							<a
								className="footer__link"
								href="https://tearribles.co.uk/"
							>
								United Kingdom
							</a>
							{domain !== "tearribles.myshopify.com" ? (
								<a
									className="footer__link"
									href="https://tearribles.com/"
								>
									United States
								</a>
							) : null}
						</div>
						<div className="footer__links">
							<h4 className="footer__link-heading">Follow Us</h4>
							<a
								href="https://www.facebook.com/Tearribleinstincts/"
								className="footer__link"
								target="_blank"
								rel="noopener noreferrer"
							>
								Facebook
							</a>
							<a
								href="https://www.instagram.com/tearribleinstincts/?hl=en"
								className="footer__link"
								target="_blank"
								rel="noopener noreferrer"
							>
								Instagram
							</a>
							<a
								href="https://twitter.com/tearribles"
								className="footer__link"
								target="_blank"
								rel="noopener noreferrer"
							>
								Twitter
							</a>
						</div>
						<div className="footer__links">
							<h4 className="footer__link-heading">
								Boring Things
							</h4>
							<a
								className="footer__link"
								href="https://tearribles.myshopify.com/2001666099/policies/3162767411.html"
								target="_blank"
								rel="noopener noreferrer"
							>
								Privacy Policy
							</a>
							<a
								className="footer__link"
								href="https://tearribles.myshopify.com/2001666099/policies/3162800179.html"
							>
								Terms & Conditions
							</a>
							<a
								className="footer__link"
								href="mailto:support@tearribles.com"
							>
								Contact
							</a>
						</div>
					</div>
					<div className="footer__bottom">
						&copy; 2018-{now.getFullYear()}
						<br />
						Tearrible Instincts
					</div>
				</div>
				<div className="footer__tiny">{tinyImage}</div>
			</Container>
		</div>
	);
};

export default Footer;
