import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { debounce } from "lodash";
import qs from "qs";
import { useLocation } from "@reach/router";
import TagManager from "react-gtm-module";

// eslint-disable-next-line
import Banner from "../components/Banner/banner";
import Header from "../components/Header/header";
import Nav from "../components/Nav/nav";
import Footer from "../components/Footer/footer";

import "../normalize.scss";
import "../global.scss";
import "../app.scss";
import "./layout.scss";
import { useDataLayer } from "../hooks/useDataLayer";
import { CartTracker } from "../components/CartTracker/cart-tracker";

const PRE_ORDER_AUTH_KEY = "_draco-vip-virus";
const QUERY_STRING_STORE = "_draco-qs";

const currentVIPCampaigns = ["snowball"];

const RouteTracker = (props) => {
	const { gtagInitialized } = props;

	const location = useLocation();
	useDataLayer(gtagInitialized);

	useEffect(() => {
		if (gtagInitialized) {
			const { pathname } = location;
			const dataLayer = {
				event: "pageview",
				pagePath: pathname,
			};
			TagManager.dataLayer({ dataLayer });
		}
	}, [location, gtagInitialized]);

	return null;
};

const withContext = (WrapperComponent) => {
	class ContextConsumer extends Component {
		componentDidMount() {
			this.context?.cart?.subscribe(() => this.forceUpdate());
		}

		render() {
			return (
				<WrapperComponent
					{...this.props}
					{...this.context}
					cart={this.context?.cart?.cart}
				/>
			);
		}
	}

	ContextConsumer.contextTypes = {
		geoLocation: PropTypes.string,
		gdprRequired: PropTypes.bool,
		cart: PropTypes.object.isRequired,
		setCart: PropTypes.func.isRequired,
		resetCart: PropTypes.func.isRequired,
		acceptedGDPR: PropTypes.bool.isRequired,
		gtagInitialized: PropTypes.bool.isRequired,
		setGDPR: PropTypes.func.isRequired,
	};

	return ContextConsumer;
};

class LayoutProvider extends Component {
	constructor(props) {
		super(props);

		this.state = {
			displayNav: false,
			banner: false,
			preOrderAuthorized: false,
			queryString: null,
			popupComponent: null,
		};

		this.setPreOrderAuth = this.setPreOrderAuth.bind(this);
		this.toggleMobileNavigation = this.toggleMobileNavigation.bind(this);
		this.handleBannerClose = this.handleBannerClose.bind(this);
		this.setPopupComponent = this.setPopupComponent.bind(this);
	}

	nonHeader = ["/login"];

	nonFooter = ["/login"];

	componentDidMount() {
		let query = {};

		const storedQueryString = localStorage.getItem(QUERY_STRING_STORE);
		if (storedQueryString) {
			const stored = JSON.parse(storedQueryString);
			query = { ...query, ...stored };
		}

		if (this.props.location?.search) {
			const search = this.props.location.search.replace("?", "");
			const newParams = qs.parse(search);
			query = { ...query, ...newParams };
			localStorage.setItem(QUERY_STRING_STORE, JSON.stringify(query));
		}

		if (Object.entries(query).length > 0) {
			this.setState({ queryString: query });
		}

		// HALLOWEEN VIP LOGIC
		if (this.props.location?.pathname.includes("virus")) {
			this.setPreOrderAuth(1);
		}
		if (query.vip && query.vip !== "false") {
			this.setPreOrderAuth(query.vip);
		}

		if (query.vip === "false") {
			this.setPreOrderAuth("false");
		}

		let preOrderAuth = localStorage.getItem(PRE_ORDER_AUTH_KEY);

		if (preOrderAuth === "false") {
			preOrderAuth = false;
		}

		if (preOrderAuth) {
			this.setState({ preOrderAuthorized: preOrderAuth });
		}

		setTimeout(() => {
			this.setState({ banner: true });
		}, 2000);
	}

	setPreOrderAuth(authorized) {
		if (authorized === "false") {
			authorized = false;
		}
		if (!currentVIPCampaigns.includes(authorized)) {
			authorized = false;
		}
		this.setState({ preOrderAuthorized: authorized });
		localStorage.setItem(PRE_ORDER_AUTH_KEY, authorized);
	}

	handleBannerClose() {
		this.setState({ banner: false });
	}

	setPopupComponent(popupComponent) {
		this.setState({ popupComponent });
	}

	toggleMobileNavigation = debounce(() => {
		let displayNav = !this.state.displayNav;
		this.setState({ displayNav });
	}, 150);

	render() {
		const children = React.Children.map(
			this.props.children,
			(child, index) => {
				return React.cloneElement(child, {
					...this.state,
					...this.props,
					setPreOrderAuth: this.setPreOrderAuth,
					handleBannerClose: this.handleBannerClose,
					toggleMobileNavigation: this.toggleMobileNavigation,
					setPopupComponent: this.setPopupComponent,
				});
			}
		);

		const linkTags = [
			{
				rel: "apple-touch-icon",
				sizes: "180x180",
				href: "/apple-touch-icon.png",
			},
			{
				rel: "icon",
				type: "image/png",
				sizes: "32x32",
				href: "/favicon-32x32.png",
			},
			{
				rel: "icon",
				type: "image/png",
				sizes: "16x16",
				href: "/favicon-16x16.png",
			},
			{ rel: "manifest", href: "/site.webmanifest" },
			{
				rel: "mask-icon",
				href: "/safari-pinned-tab.svg",
				color: "#2b4bd1",
			},
		];

		const metaTags = [
			{
				name: "description",
				content:
					'The first line of innovative dog toys that cater to dogs\' prey instincts. Interactive toys that your dog can "tear apart", and you can put right back together in seconds.',
			},
			{
				name: "keywords",
				content:
					"dogs, dog toys, interactive dog toy, tear apart dog toy, velcro dog toy, pull apart dog toy, tearribles, tearables, tearable, plush dog toy, stuffed animal dog toy",
			},
			{ name: "msapplication-TileColor", content: "#2d89ef" },
			{ name: "theme-color", content: "#ffffff" },
			{
				name: "facebook-domain-verification",
				content: "qbndalyraucdmr1ky89y3itafk0qzk",
			},
		];

		const renderHeader = !this.nonHeader.includes(
			this.props.location?.pathname
		);
		const renderFooter = !this.nonFooter.includes(
			this.props.location?.pathname
		);

		return (
			<div className="layout">
				<Helmet
					title="Tearribles - Interactive Dog Toys"
					meta={metaTags}
					link={linkTags}
				/>
				<RouteTracker {...this.props} />
				{this.state.popupComponent && (
					<div className="layout__popup">
						<div className="layout__popup-card">
							{this.state.popupComponent}
						</div>
					</div>
				)}
				{renderHeader && this.state.banner && (
					<Banner
						handleBannerClose={this.handleBannerClose}
						geoLocation={this.props.geoLocation}
						{...this.state}
					/>
				)}
				{renderHeader && (
					<Header
						cart={this.props.cart}
						displayNav={this.state.displayNav}
						toggleMobileNavigation={this.toggleMobileNavigation}
						preOrderAuthorized={this.state.preOrderAuthorized}
					/>
				)}
				<Nav
					displayNav={this.state.displayNav}
					toggleMobileNavigation={this.toggleMobileNavigation}
					preOrderAuthorized={this.state.preOrderAuthorized}
				/>
				{(this.props.geoLocation === "US" ||
					this.props.geoLocation === "CA" ||
					this.props.geoLocation === "GB" ||
					this.props.geoLocation === "AU") && (
					<CartTracker
						cart={this.props.cart}
						location={this.props.location}
						geoLocation={this.props.geoLocation}
					/>
				)}
				<div className="layout__content-wrapper">{children}</div>
				{renderFooter && <Footer {...this.props} />}
			</div>
		);
	}
}

export default withContext(LayoutProvider);
