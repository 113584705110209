import React from "react";
import {
	useQuery,
	useQueryClient,
	QueryClient,
	QueryClientProvider,
} from "react-query";

import CartProvider from "./cart";
import GDRPProvider from "./gdpr";
import ErrorBoundary from "./error-boundary";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: false,
			staleTime: 30000,
		},
	},
});

// eslint-disable-next-line react/prop-types,react/display-name
export default ({ element }) => {
	return (
		<ErrorBoundary>
			<QueryClientProvider client={queryClient}>
				<CartProvider>
					<GDRPProvider>{element}</GDRPProvider>
				</CartProvider>
			</QueryClientProvider>
		</ErrorBoundary>
	);
};
