import React, { Component } from 'react';

import './countdown.scss'

class Countdown extends Component {

    constructor(props) {
        super(props);

        this.state = {
            time: null,
            startTime: new Date("Mar 01, 2019 00:59:59"),
            endTime: new Date("Mar 01, 2019 23:59:59")
        }

    }

    componentDidMount() {
        this.startTimer(new Date());
        setInterval(() => {
            this.startTimer(new Date());
        }, 1000);
    }

    startTimer(now) {

        // If end time has passed.  Return null and the timer
        // will disappear.
        if (this.state.startTime > now) {
            this.setState({ time: null });
            return;
        }

        // If end time has passed.  Return null and the timer
        // will disappear.
        if (this.state.endTime < now) {
            this.setState({ time: null });
            return;
        }

        const seconds = this.state.endTime - now;
        const time = this.calculateTimer(seconds);
        this.setState({ time })
    }

    calculateTimer(timeDiff) {
        const time = timeDiff / 1000;
        const days = Math.floor(time / (60 * 60 * 24));

        const remainingDay = time % (60 * 60 * 24);
        const hours = Math.floor(remainingDay / (60 * 60));

        const remainingHour = time % (60 * 60);
        const minutes = Math.floor(remainingHour / 60);

        const seconds = Math.floor(time % 60);
        
        return {
            days,
            hours,
            minutes,
            seconds
        }
    }

    render() {

        if (
            !this.state.time 
            || this.state.time < this.state.endTime
        ) {
            return null;
        }

        // eslint-disable-next-line
        const { days, hours, minutes, seconds } = this.state.time;

        return (
            <div className="countdown">
                    <div>{hours} hr &nbsp;&nbsp;{minutes} min &nbsp;&nbsp;{seconds} sec</div>
                    {/* <div>
                        <div className="countdown__number">{days}</div>
                        <div className="countdown__type">Days</div>
                    </div> */}
            </div>
        )
    }

}

export default Countdown;