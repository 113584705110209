import React, { Component } from "react";

import "./container.scss";

class Container extends Component {
	render() {
		const { style } = this.props;

		return (
			<div className="container" style={style}>
				{this.props.children}
			</div>
		);
	}
}

export default Container;
