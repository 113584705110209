import axios from 'axios';

import parseCheckoutResponseFactory from '../utils/parse-checkout-response';
import checkoutFragment from './checkout.fragment';
import { submitSentryError } from '../providers/error-boundary';
import { BACKEND_URL } from '../config';

const GET_CART_QUERY = `
    query getCart($id: ID!) {
        node(id: $id) {
            __typename
            ...cart
        }
    }

    ${checkoutFragment}
`

const getCart = (cartId, setCart) => {

    const variables = {
        id: cartId
    }

    const payload = {
        query: GET_CART_QUERY,
        variables
    }

    let headers = {
        "Content-Type": 'application/json'
    }

    const parseCheckoutResponse = parseCheckoutResponseFactory('node');

    axios.post(BACKEND_URL, payload, { headers })
        .then(parseCheckoutResponse)
        .then((result) => setCart(result))
        .catch((error) => submitSentryError(error, { tag: { api: 'Shopify', action: 'getCart' } }));

}

export default getCart;