import { globalHistory, useLocation } from '@reach/router';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import Cookies from 'universal-cookie';

const getURLParams = function (name, url) {
    if (!url || !name) return null;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
};


export const useDataLayer = (gtagInitialized) => {

    const cookies = new Cookies();
    const location = useLocation();
    const searchTermQuery = [getURLParams('q', location.href)]
    // const value = cookies.get(key)

    const [DL, setDL] = useState({
        dynamicCart: true,  // if cart is dynamic (meaning no refresh on cart add) set to true
        debug: false, // if true, console messages will be displayed
        cart: null,
        wishlist: null,
        removeCart: null
    })

    const dataLayer = {
        push: (obj, logName) => {
            TagManager.dataLayer({
                dataLayer: obj
            })

            if (DL.debug && logName) {
                console.log(`${logName}: ` + JSON.stringify(obj, null, " "));
            }
        }
    }

    useEffect(() => {
        // Begin
        if (gtagInitialized) {
            dataLayer.push({ 'event': 'Begin DataLayer' });
        } else {
            return () => null;
        }

        // Landing Page
        const landingCookie = cookies.get('landingPage');
        let landingPage;
        if (landingCookie === undefined || landingCookie.length === 0) {
            landingPage = true;
            cookies.remove('landingPage', { path: '/' });
            cookies.set('landingPage', 'landed', { path: '/' });
        } else {
            landingPage = false;
            cookies.remove('landingPage', { path: '/' });
            cookies.set('landingPage', 'refresh', { path: '/' });
        }

        if (DL.debug) {
            console.log('Landing Page: ' + landingPage);
        }

        if (landingPage) {
            dataLayer.push({
                'pageType': 'Landing',
                'event': 'Landing'
            });

            if (DL.debug) {
                console.log('DATALAYER: Landing Page fired.');
            }
        }

        // Log State
        const logState = {
            logState: 'Logged Out',
            'pageType': 'Log State',
            'event': 'Log State'
        }

        dataLayer.push(logState);
        if (DL.debug) {
            console.log("Log State" + " :" + JSON.stringify(logState, null, " "));
        }

        // Home page
        if (document.location.pathname == "/") {
            var homepage = {
                'pageType': 'Homepage',
                'event': 'Homepage'
            };
            dataLayer.push(homepage);
            if (DL.debug) {
                console.log("Homepage" + " :" + JSON.stringify(homepage, null, " "));
            }
        }

        // Fire on load.
        dataLayer.push({
            'event': 'DataLayer Loaded'
        });
        console.log('DATALAYER: DataLayer Loaded.');

    }, [gtagInitialized])

    return {
        dataLayer,
    }

}