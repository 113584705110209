import deleteLineItem from "../actions/delete-item-checkout";
import addLineItem from "../actions/add-item-checkout";
import { AWAIT_SET_CART } from "../providers/cart";

const PoopBagIDs = {
	BLACK: "Shopify__ProductVariant__Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8xNjk0MTA2OTUzMzIzNQ==",
	PINK: "Shopify__ProductVariant__Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8xNjk0MTA2OTU2NjAwMw==",
	SKY_BLUE:
		"Shopify__ProductVariant__Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8xNjk0MTA2OTUwMDQ2Nw==",
	GREEN: "Shopify__ProductVariant__Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8xNjk0MTA2OTU5ODc3MQ==",
	YELLOW: "Shopify__ProductVariant__Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8xNjk0MTA2OTYzMTUzOQ==",
	ROLL: "Shopify__ProductVariant__Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDU4MDMyOTAxMzI5OQ==",
};

const twoPoopBagBoxID =
	"Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMjQ1NTgyNTkxNTk1NQ==";

const oneTreePlanted = "gid://shopify/ProductVariant/49309754786110";
const base64OneTreePlanted =
	"Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80OTMwOTc1NDc4NjExMA==";

const checkSpecialOffer = async (cart, setCart, query) => {
	const domain =
		process.env.GATSBY_MYSHOPIFY_URL || "tearribles.myshopify.com";
	const { lineItems, id, subtotalPrice } = cart;

	if (!id || !setCart) {
		return;
	}

	if (!lineItems || !Array.isArray(lineItems) || lineItems.length === 0) {
		return;
	}

	const regularItemIncluded = lineItems.find((item) => {
		return (
			!item.title.includes("Special Offer") &&
			!item.title.includes("FREE GIFT")
		);
	});

	if (!regularItemIncluded) {
		deleteLineItem(lineItems[0], id, setCart);
	}

	// Free tree planted
	// if (domain === "tearribles.myshopify.com") {
	// 	const freeTreeIncluded = lineItems.find((item) => {
	// 		return item.variant === oneTreePlanted;
	// 	});
	//
	// 	if (!freeTreeIncluded) {
	// 		return addLineItem(
	// 			{ id: base64OneTreePlanted, quantity: 1 },
	// 			id,
	// 			setCart,
	// 		).then(() => AWAIT_SET_CART);
	// 	}
	//
	// 	const emptyCart =
	// 		lineItems.filter((item) => {
	// 			return item.variant !== oneTreePlanted;
	// 		}).length === 0;
	//
	// 	if (emptyCart && freeTreeIncluded) {
	// 		return deleteLineItem(freeTreeIncluded, id, setCart);
	// 	}
	// }

	// Free poop bag holder for 2 boxes of poop bags
	// const hasTwoPoopbagBoxes = lineItems.find((item) => {
	//     return item.variant === twoPoopBagBoxID;
	// })
	//
	// const freePoopBagHolderIncluded = lineItems.find((item) => {
	//     return item.variant === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8xNjk0MTA2OTUzMzIzNQ=='
	// })
	//
	// if (hasTwoPoopbagBoxes && !freePoopBagHolderIncluded) {
	//     return addLineItem({ id: PoopBagIDs.BLACK, quantity: 1 }, id, setCart)
	//         .then(() => AWAIT_SET_CART);
	// }

	// Remove to add back poop bag special offer again.
	// return Promise.resolve(null);

	// // FREE POOP BAG SPECIAL OFFER
	// const subTotal = parseFloat(subtotalPrice?.amount) * 100;
	// // const freePoopBagIncluded = lineItems.find((item) => {
	// //     return item.title.includes("FREE GIFT")
	// // })
	// // const poopBagId = PoopBagIDs.SKY_BLUE;
	// const poopBagPricePoint = 6000;

	// // Add bag if over $50.
	// if (subTotal > poopBagPricePoint && !freePoopBagIncluded) {
	//     return addLineItem({ id: poopBagId, quantity: 1 }, id, setCart)
	//         .then(() => AWAIT_SET_CART);
	// }

	// // END FREE POOP BAG

	return Promise.resolve(null);
};

export default checkSpecialOffer;
