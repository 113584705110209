import React from "react";

import closeIcon from "../../assets/x.svg";
import "./banner.scss";

// eslint-disable-next-line
import Countdown from "../Countdown/countdown";

const bannerText = {
	"tearriblesau.myshopify.com": <>FREE SHIPPING on orders of $75</>,
	"tearribles.myshopify.com": "FREE Shipping on orders over $50",
	default: "FREE Shipping on orders over $50",
};

const vipBannerText = {
	"tearriblesau.myshopify.com": <>FREE SHIPPING on orders of $75</>,
	"tearribles.myshopify.com": "FREE Shipping on orders over $50",
	default: "FREE Shipping on orders over $50",
};

const allowedGeos = ["AU", "US"];

const Banner = (props) => {
	// Remove to add banner back in.
	// if (1) return null;
	const domain = process.env.GATSBY_MYSHOPIFY_URL || "default";
	let { geoLocation } = props;

	if (!allowedGeos.includes(geoLocation)) {
		return null;
	}

	if (!bannerText[domain]) {
		return null;
	}

	let verbiage = props.verbiage ? (
		props.verbiage
	) : (
		<>{bannerText[domain] || bannerText.default}</>
	);

	let mobileVerbiage = props.verbiage ? (
		props.verbiage
	) : (
		<>
			{/* <span style={{ display: "inline" }}>HOLIDAY SALE!</span><br /> */}
			{bannerText[domain] || bannerText.default}
		</>
	);

	if (props.preOrderAuthorized) {
		verbiage = props.verbiage ? (
			props.verbiage
		) : (
			<>
				{/* <span style={{ display: "inline" }}>HOLIDAY SALE!</span>  */}
				{vipBannerText[domain] || vipBannerText.default}
			</>
		);

		mobileVerbiage = props.verbiage ? (
			props.verbiage
		) : (
			<>
				{/* <span style={{ display: "inline" }}>HOLIDAY SALE!</span><br /> */}
				{vipBannerText[domain] || vipBannerText.default}
			</>
		);
	}

	return (
		<div>
			<div className="banner">
				{/* <span style={{ display: "inline" }} className="mobile-only">
					HAPPY MOTHER'S DAY!
				</span> */}
				{verbiage}
				{/* <Countdown /> */}
				<img
					className="banner__close"
					src={closeIcon}
					onClick={props.handleBannerClose}
					alt="Close banner."
				/>
			</div>
			<div
				className="mobile-banner"
				style={props.funnel ? { top: "0" } : {}}
			>
				{mobileVerbiage}
				{/* <Countdown /> */}
				<img
					className="banner__close"
					src={closeIcon}
					onClick={props.handleBannerClose}
					alt="Close banner."
				/>
			</div>
		</div>
	);
};

export default Banner;
