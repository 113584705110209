import React from "react";
import AnimateHeight from "react-animate-height";
import Link from "gatsby-link";

import "./nav.scss";

const Nav = (props) => {
	const domain = process.env.GATSBY_MYSHOPIFY_URL || "default";
	const preOrderAuthorized = props.preOrderAuthorized;

	const { displayNav, toggleMobileNavigation } = props;
	const hideStyle = {
		display: "none",
	};

	const closeNav = (e) => {
		toggleMobileNavigation();
	};

	const linkClasses = ["nav__link"];
	if (displayNav) {
		linkClasses.push("nav__link--animate");
	}

	const shopLinkClasses = linkClasses.slice();
	shopLinkClasses.push("nav__link--primary");

	const subLinkClasses = linkClasses.slice();
	subLinkClasses.push("nav__link--sub");

	return (
		<div className="nav" style={!displayNav ? hideStyle : null}>
			<AnimateHeight
				height={displayNav ? "auto" : 0}
				animateOpacity={true}
			>
				<div className="nav__content">
					<div className="nav__links">
						<Link
							to="/"
							className={linkClasses.join(" ")}
							onClick={closeNav}
						>
							Home
						</Link>
						<Link
							to="/products"
							className={shopLinkClasses.join(" ")}
							onClick={closeNav}
						>
							Shop
						</Link>
						<Link
							to="/products"
							className={linkClasses.join(" ")}
							onClick={closeNav}
						>
							<div className="nav__link--sub">Dog Toys</div>
						</Link>
						{domain !== "tearriblesau.myshopify.com" ? (
							<>
								{domain !== "tearriblesau.myshopify.com" ? (
									<Link
										to="/products/combos"
										className={linkClasses.join(" ")}
										onClick={closeNav}
									>
										<div className="nav__link--sub">
											Combos
										</div>
									</Link>
								) : null}
								<Link
									to="/collections/baloomba-yak-chews"
									className={linkClasses.join(" ")}
									onClick={closeNav}
								>
									<div className="nav__link--sub">
										Treats & Chews
									</div>
								</Link>
								<Link
									to="/products/gear"
									className={linkClasses.join(" ")}
									onClick={closeNav}
								>
									<div className="nav__link--sub">Gear</div>
								</Link>
								<Link
									to="/product/tearribles-digital-gift-card"
									className={linkClasses.join(" ")}
									onClick={closeNav}
								>
									<div className="nav__link--sub">
										Gift Cards
									</div>
								</Link>
							</>
						) : null}
						<Link
							to="/faq"
							className={linkClasses.join(" ")}
							onClick={closeNav}
						>
							FAQ
						</Link>
						<Link
							to="/story"
							className={linkClasses.join(" ")}
							onClick={closeNav}
						>
							Story
						</Link>
						<Link
							to="/blog/prey-drive"
							className={linkClasses.join(" ")}
							onClick={closeNav}
						>
							Blog
						</Link>
					</div>
				</div>
			</AnimateHeight>
		</div>
	);
};

export default Nav;
