import React, { Component } from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import TagManager from "react-gtm-module";

import config from "../config";
import getGeoLocation from "../actions/get-geo-location";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import removeIcon from "../assets/x-square.svg";

const GEO_LOCATION_KEY = "_draco-geo-location";

const checkGDPRRequirement = (geoLocation) => {
	return geoLocation !== "US" && geoLocation !== "AU";
};

// TODO: Track time of cookie acceptance.
// TODO: Check History Change Triggers in Google Tag Manager.

class GDPRProvider extends Component {
	constructor(props) {
		super(props);

		this.state = {
			geoLocation: null,
			gdprRequired: true,
			acceptedGDPR: false,
			gtagInitialized: false,
		};

		this.acceptCookieConsent = this.acceptCookieConsent.bind(this);
		this.declineCookieConsent = this.declineCookieConsent.bind(this);
		this.checkAURedirect = this.checkAURedirect.bind(this);
		this.setGeoLocation = this.setGeoLocation.bind(this);
		this.setGDPR = this.setGDPR.bind(this);
	}

	static childContextTypes = {
		geoLocation: PropTypes.string,
		gdprRequired: PropTypes.bool,
		acceptedGDPR: PropTypes.bool.isRequired,
		gtagInitialized: PropTypes.bool.isRequired,
		setGDPR: PropTypes.func.isRequired,
	};

	checkAURedirect = (geoLocation) => {
		let domain = process.env.GATSBY_MYSHOPIFY_URL || "default";

		if (
			geoLocation &&
			geoLocation.toLowerCase() === "au" &&
			domain !== "tearriblesau.myshopify.com"
		) {
			const href = window.location.href;
			const url = new URL(href);

			const redirectUrl = new URL("https://au.tearribles.com");
			redirectUrl.search = url.search;

			window.location.href = redirectUrl.href;
		}
	};

	setGeoLocation = (geoLocation) => {
		// this.checkAURedirect(geoLocation);

		const gdprRequired = checkGDPRRequirement(geoLocation);
		this.setState({ geoLocation, gdprRequired });
	};

	setGDPR = (value) => {
		this.setState({ acceptedGDPR: value });
	};

	componentDidMount() {
		// GEO LOCATION
		const geoLocation = sessionStorage.getItem(GEO_LOCATION_KEY);

		if (geoLocation) {
			this.setGeoLocation(geoLocation);
		} else {
			getGeoLocation().then((result) => {
				this.setGeoLocation(result);
				sessionStorage.setItem(GEO_LOCATION_KEY, result);
			});
		}

		// COOKIE CONSENT
		const consent = getCookieConsentValue();
		if (consent === "true") {
			this.setState({ acceptedGDPR: true });
		}
	}

	componentDidUpdate() {
		const { acceptedGDPR, gtagInitialized, gdprRequired } = this.state;
		if ((acceptedGDPR || !gdprRequired) && !gtagInitialized) {
			TagManager.initialize({
				gtmId: config.GOOGLE_TAG,
			});
			this.setState({ gtagInitialized: true });
		}
	}

	getChildContext() {
		return {
			...this.state,
			setGDPR: this.setGDPR,
		};
	}

	acceptCookieConsent() {
		this.setState({ acceptedGDPR: true });
	}

	declineCookieConsent() {
		// Change GDPR required to true to ensure cookies are not tracked.
		this.setState({ acceptedGDPR: false, gdprRequired: true });
	}

	render() {
		const { children } = this.props;
		const { gdprRequired, acceptedGDPR } = this.state;
		const domain =
			process.env.GATSBY_MYSHOPIFY_URL || "tearribles.myshopify.com";
		const facebookPixel =
			domain === "tearriblesau.myshopify.com"
				? config.AU_FACEBOOK_PIXEL
				: config.FACEBOOK_PIXEL;

		return (
			<React.Fragment>
				<Helmet>
					<link
						rel="preconnect dns-prefetch"
						href="https://triplewhale-pixel.web.app/"
						crossorigin
					/>
					<link
						rel="preconnect dns-prefetch"
						href="https://api.config-security.com/"
						crossorigin
					/>
					<link
						rel="preconnect dns-prefetch"
						href="https://conf.config-security.com/"
						crossorigin
					/>
					<script src="https://d2xrtfsb9f45pw.cloudfront.net/js/bg-thirdparty-track.js"></script>
				</Helmet>

				{(acceptedGDPR || !gdprRequired) &&
					process.env.NODE_ENV === `production` && (
						<Helmet>
							{/* FB PIXEL */}
							<script>
								{`
                                !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                                n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
                                n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
                                t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
                                document,'script','https://connect.facebook.net/en_US/fbevents.js');
                                fbq('init', '${facebookPixel}');
                                fbq('track', 'PageView');
                            `}
							</script>

							{/* GOOGLE TAG */}
							<script
								async
								src={`https://www.googletagmanager.com/gtag/js?id=${config.GOOGLE_TAG}`}
							></script>
							<script>
								{`
                                window.dataLayer = window.dataLayer || [];
                                function gtag(){dataLayer.push(arguments);}
                                gtag('js', new Date());
                                gtag('config', '${config.GOOGLE_TAG}');
                            `}
							</script>

							{/* SMART LOOK */}
							<script type="text/javascript">{`
							window.smartlook||(function(d) {
								var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
								var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
								c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
							})(document);
							smartlook('init', '8ffb5eae483e9a55bfe3abe037a91f6f2606d167', { region: 'eu' });
							`}</script>

							{/* Clickcease.com tracking */}
							<script type="text/javascript">{`
                            (function() {
                                var script = document.createElement('script');
                                script.async = true; script.type = 'text/javascript';
                                var target = 'https://www.clickcease.com/monitor/stat.js';
                                script.src = target;var elem = document.head;elem.appendChild(script);
                            })();
							`}</script>
							<noscript>{`
								<a href='https://www.clickcease.com' rel='nofollow'><img src='https://monitor.clickcease.com/stats/stats.aspx' alt='ClickCease' /></a>
							`}</noscript>

							{/* Gorgias Chat */}
							<script
								id="gorgias-chat-widget-install-v2"
								src="https://config.gorgias.chat/gorgias-chat-bundle-loader.js?applicationId=23189"
							></script>

							{/* TripleWhale */}
							<script>
								{`
								 window.TriplePixelData={TripleName:"${domain}",ver:"2.16",plat:"SHOPIFY",isHeadless:true},function(W,H,A,L,E,_,B,N){function O(U,T,P,H,R){void 0===R&&(R=!1),H=new XMLHttpRequest,P?(H.open("POST",U,!0),H.setRequestHeader("Content-Type","text/plain")):H.open("GET",U,!0),H.send(JSON.stringify(P||{})),H.onreadystatechange=function(){4===H.readyState&&200===H.status?(R=H.responseText,U.includes("/first")?eval(R):P||(N[B]=R)):(299<H.status||H.status<200)&&T&&!R&&(R=!0,O(U,T-1,P))}}if(N=window,!N[H+"sn"]){N[H+"sn"]=1,L=function(){return Date.now().toString(36)+"_"+Math.random().toString(36)};try{A.setItem(H,1+(0|A.getItem(H)||0)),(E=JSON.parse(A.getItem(H+"U")||"[]")).push({u:location.href,r:document.referrer,t:Date.now(),id:L()}),A.setItem(H+"U",JSON.stringify(E))}catch(e){}var i,m,p;A.getItem('"!nC\`')||(_=A,A=N,A[H]||(E=A[H]=function(t,e,a){return void 0===a&&(a=[]),"State"==t?E.s:(W=L(),(E._q=E._q||[]).push([W,t,e].concat(a)),W)},E.s="Installed",E._q=[],E.ch=W,B="configSecurityConfModel",N[B]=1,O("https://conf.config-security.com/model",5),i=L(),m=A[atob("c2NyZWVu")],_.setItem("di_pmt_wt",i),p={id:i,action:"profile",avatar:_.getItem("auth-security_rand_salt_"),time:m[atob("d2lkdGg=")]+":"+m[atob("aGVpZ2h0")],host:A.TriplePixelData.TripleName,plat:A.TriplePixelData.plat,url:window.location.href,ref:document.referrer,ver:A.TriplePixelData.ver},O("https://api.config-security.com/event",5,p),O("https://api.config-security.com/first?host=${domain}&plat=SHOPIFY",5)))}}("","TriplePixel",localStorage);`}
							</script>
						</Helmet>
					)}

				{gdprRequired && (
					<CookieConsent
						className="cookie-consent"
						buttonText="Got it!"
						enableDeclineButton
						declineButtonText={
							<img src={removeIcon} alt="Close cookie consent." />
						}
						declineButtonClasses="CookieConsent--Close"
						buttonClasses="CookieConsent--Accept"
						onAccept={this.acceptCookieConsent}
						disableStyles
					>
						<p>
							This website uses cookies to ensure you get the best
							experience on our website.{" "}
							<a
								target="_blank"
								href="https://tearribles.myshopify.com/2001666099/policies/3162767411.html"
							>
								Learn More
							</a>
						</p>
					</CookieConsent>
				)}
				{React.Children.only(children)}
			</React.Fragment>
		);
	}
}

export default GDPRProvider;
